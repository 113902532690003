import React from 'react'

export default function Index({ children }: any) {
  return (
    <>{
      children
    }</>
  )
}

