import React, { useState } from "react";
import { Avatar, Box, Button, IconButton, Typography } from "@mui/material";
import softSensorLogo from "../../assets/softSensorLogo.svg";
import chatIcon from "../../assets/chatIcon";
import settingIcon from "../../assets/settingIcon";
import { useNavigate } from "react-router-dom";
import { Search, AcUnit } from "@mui/icons-material";

const Index = () => {
    const navigate = useNavigate();
    const [selected, setSelected] = useState("Bond-LLM");

    const options = [
        { title: "Bond-LLM", onClick: () => navigate("/bond_llm"), icon: chatIcon },
        { title: "Search", onClick: () => navigate("/search"), icon: Search },
        { title: "Mongo DB chatbot", onClick: () => navigate("/mongo_DB_chatbot"), icon: AcUnit },
        { title: "Setting", onClick: () => navigate("/setting"), icon: settingIcon },
    ];

    return (
        <Box
            sx={{
                minWidth: "1.7rem",
                width: "5vw",
                background: "#fff",
                borderRadius: "0px 10px 10px 0px",
                boxShadow: "2px 0px 11px 0px #aaa",
                marginRight: "0.5rem",
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                gap: 3,
                py: "0.3rem",
            }}
        >
            <Box>
                <IconButton onClick={() => navigate("/chats")}>
                    <Avatar variant="rounded" src={softSensorLogo}></Avatar>
                </IconButton>
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1 }}>
                {options?.map((elem: any) => (
                    <Button
                        key={elem?.title}
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: 1,
                            textTransform: "none",
                            borderRadius: "10px",
                            background: selected === elem?.title ? "#175BA51C" : "inherit",
                        }}
                        onClick={() => {
                            setSelected(elem?.title);
                            elem?.onClick();
                        }}
                    >
                        <elem.icon />
                        <Typography sx={{ color: "#6E7986", fontSize: "0.9rem" }}>{elem?.title}</Typography>
                    </Button>
                ))}
            </Box>
            <Box sx={{ py: 0.5, height: "100%", display: "flex", alignItems: "flex-end" }}>
                <Button>
                    <Avatar
                        sx={{
                            border: "4px solid rgba(0, 0, 0, 0.13)",
                            background: "#fff",
                            color: "#2C4860",
                            fontSize: "1rem",
                            fontWeight: 600,
                        }}
                    >
                        PG
                    </Avatar>
                </Button>
            </Box>
        </Box>
    );
};

export default Index;
