import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import Routes from "./Route";
import ThemeClient from "./context/theme";
import { Context } from "./context/setting";
import "./App.css";
import { useState } from "react";
import SnackbarAlert from "./screens/Snackbar/Snackbar";

const { store, persistor } = createStore();

function App() {
    const [contextStore, setContext] = useState<any>({});
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
        window.history.pushState(null, "", window.location.href);
    };
    return (
        <Provider store={store}>
            <Context.Provider value={{ contextStore, setContext }}>
                <PersistGate persistor={persistor}>
                    <ThemeClient>
                        <SnackbarAlert />
                        <Routes />
                    </ThemeClient>
                </PersistGate>
            </Context.Provider>
        </Provider>
    );
}

export default App;
