const GLOBAL_RELEASE_VERSION: string = "0.0.1";
const TEST_RELEASE_VERSION: string = "0.0.1";

type Single_Environment = {
    env: string;
    USER_URL: string;
    LOGS: boolean;
    RELEASE: string;
};

type Environment_ = {
    [key: string]: Single_Environment;
}



const _Environments: Environment_ = {
    production: {
        env: "production",
        USER_URL: "prod_url_here",
        LOGS: false,
        RELEASE: GLOBAL_RELEASE_VERSION,
    },
    staging: {
        env: "staging",
        USER_URL: "staging_url_here",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
    },
    development: {
        env: "development",
        USER_URL: "development_url_here",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
    },
    local: {
        env: "local",
        USER_URL: "http://localhost:3000",
        LOGS: true,
        RELEASE: TEST_RELEASE_VERSION,
    }
};

function getEnvironment() {
    let env;
    if (window.location.href.includes("localhost")) {
        env = "local";
    } else if (window.location.href.includes("development")) {
        env = "development";
    } else if (window.location.href.includes("staging")) {
        env = "staging";
    } else {
        env = "production";
    }
    return _Environments[env];
}

const Environment = getEnvironment();


export default Environment;