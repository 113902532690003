import React, { FC, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Fallback from "./components/Fallback";
import { Box } from "@mui/material";

import SideBar from "./screens/SideBar";
import { makeStyles } from "@mui/styles";
const Bond_LLM = React.lazy(() => import("./screens/Bond_LLM"));
const Search = React.lazy(() => import("./screens/Search"));
const Mongo_DB_Chatbot = React.lazy(() => import("./screens/Mongo_DB_Chatbot"));
const Setting = React.lazy(() => import("./screens/Setting/Index"));

const Routess: FC = ({ auth }: any) => {
    const classes = useStyles();
    return (
        <>
            <Router>
                <Box className={classes?.container}>
                    <SideBar />
                    <Routes>
                        {[
                            { path: "/bond_llm", Element: Bond_LLM },
                            { path: "/search", Element: Search },
                            { path: "/mongo_DB_chatbot", Element: Mongo_DB_Chatbot },
                            { path: "/setting", Element: Setting },
                        ].map((screen) => (
                            <Route
                                key={screen.path}
                                path={screen.path}
                                element={
                                    <Suspense fallback={<Fallback />}>
                                        <screen.Element />
                                    </Suspense>
                                }
                            />
                        ))}

                        <Route
                            path="*"
                            element={
                                <Suspense fallback={<Fallback />}>
                                    <Bond_LLM />
                                </Suspense>
                            }
                        />
                    </Routes>
                </Box>
            </Router>
        </>
    );
};

const useStyles = makeStyles({
    container: {
        display: "flex",
        height: "100vh",
        width: "100vw",
        background: "#F4FAFE",
    },
});

export default Routess;
